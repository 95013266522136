<template>
  <div>
    <CourseProviderFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(courseProviderList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, ['Title', 'Description'])
            "
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(course, index) in courseProviderList" :key="`index-${index}`">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            <span class="capitalize">
              {{ course.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description', ['Description'])">
            <span class="capitalize">
              {{ course.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :idx="index"
              :current-user="course"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(courseProviderCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
    <CourseProviderModal
      v-if="isCourseModalVisible"
      :modal="isCourseModalVisible"
      :edit="currentItem"
      @toggle="toggleCourseModal"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_COURSE_PRO"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentItem.title"
      remaining-message="Course Provider ?"
      @cancel="toggleConfirmationModal"
      @confirm="deactivateCourse"
    />
  </div>
</template>

<script>
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import { dropdownTopBottom, objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil.js'
import CourseProviderModal from '@views/admin/modals/AddAndEditCourseProvider.vue'
import { isEmpty } from 'lodash'
import fileMixins from '@/src/mixins/file-mixins'
import Loader from '@components/BaseComponent/Loader.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import CourseProviderFilter from '@src/router/views/syllabus/template/CourseProviderFilter.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import SYLLABUS_TEMPLATE from '@views/syllabus/template/syllabusTemplte.json'
import { rightBarStats } from '@views/syllabus/template/syllabus-template.ulit.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    TableAction,
    CourseProviderModal,
    Loader,
    CourseProviderFilter,
    NoRecordFound,
    UIConfirmationModal,
    Pagination,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [scrollMixin, fileMixins, GeneralMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      defaultSelectedColumns: ['Title', 'Description', 'Actions'],
      actionList: [{ name: 'EDIT' }, { name: 'Delete' }],
      tableHeaders: SYLLABUS_TEMPLATE.courseProviderTableHeaders,
      isCourseModalVisible: false,
      courseProviderList: [],
      currentItem: null,
      isLoading: false,
      confirmation: false,
      filtersData: {},
      filteredRecordLimit: 10,
      showPagination: false,
      courseProviderCounts: 0,
      forceRender: 0,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      tabRightBar: (state) => state.layout.tabRightNav,
      currentCampusScope: (state) => state.layout.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.filterRecords()
        this.setRightbarData()
        this.forceRender++
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.setRightbarData(this.courseProviderCounts)
      },
    },
  },
  created() {
    this.setRightbarData()
  },
  mounted() {
    this.filterRecords()
  },
  methods: {
    showColumnInDataTable,
    dropdownTopBottom,
    isEmpty,
    typeAction(action, currentItem, idx) {
      this.currentItem = currentItem
      switch (action) {
        case 'EDIT':
          this.toggleCourseModal()
          break
        case 'Delete':
          this.toggleConfirmationModal()

          break
      }
    },
    deactivateCourse() {
      let id = this.currentItem?.id || null
      this.deactivateCourseProvider(id).then(() => {
        this.currentItem = null
        this.confirmation = false
        this.filterRecords()
        this.$store.commit('toast/NEW', { message: 'Deleted Successfully', type: 'success' })
        this.forceRender++
      })
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentItem = null
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    filterRecords(range) {
      this.isLoading = true
      let paginationRange = this.getPaginationRange(range)
      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getCourseProvidersList(payload).then((res) => {
        this.courseProviderCounts = res?.meta?.total_records || 0
        this.showPagination = this.courseProviderCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        this.courseProviderList = res?.records
        this.isLoading = false
        this.setRightbarData(this.courseProviderCounts)
      })
    },
    getPaginationRange(range) {
      return {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
    },
    setRightbarData(length) {
      const rightBarContent = {
        header: {
          title: 'COURSE_TYPE',
          buttons: [
            {
              title: 'ADD_C',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleCourseModal()
                },
              },
            },
          ],
        },
        stats: rightBarStats(length, this.currentCampusScope),
      }
      this.setRightbarContent(rightBarContent)
    },
    toggleCourseModal(payload) {
      this.isCourseModalVisible = !this.isCourseModalVisible
      if (!this.isCourseModalVisible) this.currentItem = null
      if (payload) {
        this.filterRecords()
        this.forceRender++
      }
    },
    ...mapActions('syllabusmanager', [
      'addNewCourseProvider',
      'deactivateCourseProvider',
      'getCourseProvidersList',
    ]),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
