<template>
  <ValidationObserver @submit="addCourseProvider">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">
          <span>Edit Course Provider</span>
        </span>
        <span v-else>Add Course Provider</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <InputBox
            v-model="courseProviderData.title"
            type="text"
            name="COURSE_N"
            title="COURSE_N"
            label="COURSE_N"
            placeholder="COURSE_N"
            class="flex-1"
            rules="required"
          />
          <InputBox
            v-model="courseProviderData.description"
            type="text"
            name="COURSE_D"
            title="COURSE_D"
            label="COURSE_D"
            placeholder="COURSE_D"
            class="flex-1"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              {{ primaryButtonText }}
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      courseProviderData: {
        title: '',
        description: '',
      },
    }
  },
  computed: {
    primaryButtonText() {
      return this.edit ? 'Update' : 'Create'
    },

    ...mapState('layout', ['isLoading']),
  },
  created() {
    if (this.edit) this.setCourseData()
  },
  methods: {
    ...mapActions('syllabusmanager', ['addNewCourseProvider']),
    ...mapActions('layout', ['getCampusList']),

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async addCourseProvider() {
      try {
        this.$store.commit('layout/IS_LOADING', true)
        let data = {
          course: this.courseProviderData,
        }
        if (this.edit) data.id = this.edit.id

        await this.addNewCourseProvider(data).then(() => {
          this.$store.commit('layout/IS_LOADING', false)
          let message = this.edit ? 'Updated Successfully' : 'Created Successfully'
          this.$store.commit('toast/NEW', { message: message, type: 'success' })
        })
        this.$emit('toggle', true)
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    setCourseData() {
      this.courseProviderData.title = this.edit?.title || ''
      this.courseProviderData.description = this.edit?.description || ''
    },
  },
}
</script>
